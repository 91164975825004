.Invoice {
    font-family: "Times New Roman", serif;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 5px !important;
}
table {
    width: 100%;
    border-spacing: initial;
    table-layout: fixed;
    font-size: 12px;

}

.acknowledgement {
    font-weight: bold;
    font-size: 11px;
}
td, th,tr {
    border: 1px solid #000000;
    text-align: left;
    padding: 5px;
}

th {
    background-color: lightgray;
    text-align: center;
    font-weight: bolder;
    font-size: 13px;
}

td.align-center {
    text-align: center;
}
h1.company-name{
    margin: 0;
}

h3.company-info {
    margin: 0;
}
span.info {
    font-size: 12px;
    font-weight: lighter;
    font-style: italic;
}
p {
    margin: 0;
}

ul.styledList {
    padding: 0;
}

li.styledListItem {
    padding: 0;
}

div.right-input > div > input {
    text-align: right;
}

.MuiAutocomplete-popper .MuiAutocomplete-option {
    padding-top: 5px;
    padding-bottom: 0;
}

html body div.MuiAutocomplete-popper div.MuiPaper-root.MuiAutocomplete-paper.MuiPaper-elevation1.MuiPaper-rounded ul.MuiAutocomplete-listbox {

    padding-top: 0;
    padding-bottom: 0;
}


html body div.MuiAutocomplete-popper div.MuiPaper-root.MuiAutocomplete-paper.MuiPaper-elevation1.MuiPaper-rounded ul.MuiAutocomplete-listbox li {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 30px;
    margin: 0;
}